import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Moment from 'react-moment';
import moment from 'moment';

import { Switch, Button, Tooltip, Badge } from 'antd';
import { NumberOutlined } from '@ant-design/icons';

import StationStatus from './columns/StationStatus';

import * as ToolMath from '../tools/ToolMath';

const renderCopyableText = (value, trimmed = true) => {
  return (
    <CopyToClipboard text={value}>
      <Tooltip title={value}>
        <Button type="dashed" block>
          {trimmed ? value.substr(0, 8) : value}
        </Button>
      </Tooltip>
    </CopyToClipboard>
  );
};

export default function SuperTableColumn(props) {
  let {
    template,
    key,
    title,
    width,
    render,
    fixed,
    sorter
  } = props;

  // if(key === 'id'){
  //   if(!width){
  //     width = 50
  //   }
  // }
  return {
    key,
    title,
    width,
    fixed,
    dataIndex: key,
    sorter,
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: (value, record) => {
      if (render) {
        return render(value, record);
      }

      switch (template) {
        case 'id': {
          return value ? (
            <CopyToClipboard text={value}>
              <Tooltip title={value.substr(0, 8)}>
                <NumberOutlined style={{ fontSize: 11 }} />
              </Tooltip>
            </CopyToClipboard>
          ) : null;
        }

        case 'isVisible':
        case 'isEnabled': {
          return (
            <Badge
              status={value ? 'success' : 'error'}
            />
          );
        }
        case 'isLocked': {
          return (
            <Badge
              status={!value ? 'success' : 'error'}
            />
          );
        }

        case 'price': {
          return value !== null ? (
            <div className="price">
              <div>
                {value < 0 ? '-' : ''}${(Math.abs(value) / 1000000).toFixed(2)}
              </div>
            </div>
          ) : null;
        }

        case 'datetime': {
          return value ? (
            <Tooltip title={moment(value).format('YYYY-MM-DD HH:mm:ss')}>
              <Moment fromNow>
                {value}
              </Moment>
            </Tooltip>
          ) : '-';
        }

        case 'stationStatus': {
          return (
            <StationStatus id={record.id} />
          );
        }

        default:
          return value;
      }
    },
  };
};
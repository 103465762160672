import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, setLocale } from 'react-redux-i18n'

import { Modal, Form, Input, Button, Spin, Select, message, Switch,Radio,InputNumber } from 'antd'
import { MinusCircleOutlined, EditOutlined, PlusOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'

const formLayout = {
  labelCol: {
    md: {span: 5},
    sm: {span: 24},
  },
  wrapperCol: {
    md: {span: 16},
  },
}

const formTailLayout = {
  wrapperCol: {
    md: {offset: 5, span: 16},
  },
}

const {TextArea} = Input
const {Option} = Select

export default function ProductTypeEditModal (props) {
  const userData = useSelector(state => state.user.value)
  const metadata = useSelector(state => state.metadata.value)
  const {productTypeId,visible, onOk, onCancel} = props
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [productType, setProductType] = useState(null)

  useEffect(() => {
    (async () => {
      try {
        const productTypeRes = await ToolRequest.request('GET', `/product-type/${productTypeId}`)
        for (let locale of metadata.supportedLocales) {
          productTypeRes.data[`name[${locale}]`] = productTypeRes.data.name[locale]
        }
        console.dir(productTypeRes)
        setProductType(productTypeRes.data)
      } catch (e) {
        message.error(e)
      }
      setLoaded(true)
    })()
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    console.dir(values)
    try {
      let name = {}
      for (let locale of metadata.supportedLocales) {
        name[locale] = values[`name[${locale}]`]
      }
      const data = {
        name,
        remark: values.remark
      }
      await ToolRequest.request('PUT', `/product-type/${productTypeId}`, data)
      message.success(I18n.t('editSuccessMessage'))
      onOk()
      setLoading(false)
    } catch (err) {
      console.dir(err)
      setLoading(false)
      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'))
        }
      }

      message.error(I18n.t('errorMessageUnknownError'))
    }
  }

  return (
    <Modal
      title={I18n.t('編輯產品類型')}
      open={visible}
      onCancel={onCancel}
      width="50%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            initialValues={productType}
            onFinish={onFinish}
          >
            {metadata.supportedLocales.map(locale => {
              return (
                <Form.Item
                  key={`name[${locale}]`}
                  label={`${I18n.t('名稱')} (${locale})`}
                  name={`name[${locale}]`}
                  rules={[
                    {
                      required: true,
                      message: I18n.t('errorMessageRequiredInputAny', {
                        name: `${I18n.t('名稱')} (${locale})`,
                      }),
                    },
                  ]}
                >
                  <Input
                    placeholder={`${I18n.t('名稱')} (${locale})`}
                  />
                </Form.Item>
              );
            })}
            <Form.Item
              label={I18n.t('備註')}
              name="remark"
            >
            <TextArea
              placeholder={I18n.t('備註')}
            />
            </Form.Item>
            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('提交')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};
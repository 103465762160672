import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'

import { PageHeader } from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  EllipsisOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'

export default function UserViewScreen (props) {
  const [loaded, setLoaded] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random().toString())

  useEffect(() => {
    (async () => {
      setLoaded(true)
    })()
  }, [])

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {},
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'type',
      title: I18n.t('類型'),
      sorter:true,
      render: (value, record) => {
        if(value){
          switch (value) {
            case 'MEMBER':{
              return '會員'
            }
            case 'ADMIN':{
              return '管理員'
            }
          }
        }
      },
    },
    {
      key: 'quota',
      title: I18n.t('家庭人數'),
      sorter:true,
      render: (value, record) => {
        if(record.type === 'MEMBER'){
          return value
        }
      },
    },
    {
      key: 'name',
      title: I18n.t('登錄名'),
      sorter:true,
    },
    {
      key: 'fullName',
      title: I18n.t('全名'),
      sorter:true,
    },
    {
      key: 'mainFamilyNumber',
      sorter:true,
      title: I18n.t('家庭號')
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('createdAt'),
      sorter:true,
    },
  ]

  const searchColumns = [
    {
      template: 'select',
      key: 'type',
      title: I18n.t('類型'),
      options: [
        {
          label:'管理員',
          value: 'ADMIN'
        },
        {
          label:'會員',
          value: 'MEMBER'
        },
      ]
    },
  ]

  const searchQueryParams = values => {
    return {
      search: values.search ? `%${values.search}%` : undefined,
      type: values.type,
    }
  }

  const loadDataSource = async (body) => {
    const userRes = await ToolRequest.request('GET', '/user', {
      ...body,
    })
    return userRes
  }

  return loaded ? (
    <PageHeader
      title={I18n.t('用戶列表')}
    >
      <SuperTable
        showSearchInput
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
        searchColumns={searchColumns}
      />
    </PageHeader>
  ) : <SpinFull/>
};

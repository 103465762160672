import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import queryString from 'querystringify'

import { Dropdown, Menu, Button, Descriptions, message, PageHeader } from 'antd'
import {
  EditOutlined,
  DownloadOutlined,
  PlusOutlined,
  EllipsisOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'

import ShopSaleRecordEditModal from './ShopSaleRecordEditModal'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'
import * as ToolTable from '../../tools/ToolTable'

export default function ShopSaleRecordViewScreen (props) {
  const userData = useSelector(state => state.user.value)
  const metadata = useSelector(state => state.metadata.value);
  const [loaded, setLoaded] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random().toString())
  const [shopSaleRecordEditModal, setShopSaleRecordEditModal] = useState(null)
  const [shops, setShops] = useState([])
  const [users, setUsers] = useState([])
  const [shopOptions, setShopOptions] = useState([])
  const [userOptions, setUserOptions] = useState([])
  const [tableParams, setTableParams] = useState(null)

  useEffect(() => {
    (async () => {
      const shopRes = await ToolRequest.request('GET', '/shop', {limit:5000})
      setShops(shopRes.data)
      const shopOptions = shopRes.data.map(item => {
        return {label:item.name[userData.locale],value:item.id}
      })
      setShopOptions(shopOptions)
      const userRes = await ToolRequest.request('GET', '/user', {limit:5000,type:'ADMIN'})
      setUsers(userRes.data)
      const userOptions = userRes.data.map(item => {
        return {label:item.fullName,value:item.id}
      })
      setUserOptions(userOptions)
      setLoaded(true)
    })()
  }, [])

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = true
        const canRemove = true
        return canEdit || canRemove ? (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    setShopSaleRecordEditModal({
                      visible: true,
                      shopSaleRecordId: record.id,
                      key: Math.random().toString(),
                    })
                    break
                  }
                }
              }
              }>
                {(
                  <Menu.Item key="edit">
                    <EditOutlined/>
                    {I18n.t('編輯店鋪銷售記錄')}
                  </Menu.Item>
                )}
              </Menu>
            }>
            <Button
              type="primary"
              icon=<EllipsisOutlined/>
            />
          </Dropdown>
        ) : null
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'shopId',
      title: I18n.t('店號'),
      render: (value, record) => {
        const shop = shops.find(item=>item.id === value)
        return shop && shop.shopNo
      },
    },
    {
      key: 'shopId',
      title: I18n.t('店名'),
      render: (value, record) => {
        const shop = shops.find(item=>item.id === value)
        return shop && shop.name ? shop.name['zh-tw'] : ''
      },
    },
    {
      key: 'terminalNo',
      title: I18n.t('終端號'),
      sorter:true
    },
    {
      key: 'operatorUserId',
      title: I18n.t('操作員'),
      render: (value, record) => {
        const user = users.find(item=>item.id === value)
        return user && user.fullName
      },
    },
    {
      key: 'payment',
      title: I18n.t('付款方式'),
      sorter:true,
      render: (value, record) => {
        return {
          OCTOPUS:'八達通',
          CASH:'現金',
        }[value] ?  {
          OCTOPUS:'八達通',
          CASH:'現金',
        }[value] : value
      },
    },
    {
      key: 'receiptNumber',
      title: I18n.t('收據編號'),
    },
    {
      key: 'transactionId',
      title: I18n.t('交易編號'),
    },
    {
      key: 'remark',
      title: I18n.t('備註'),
    },
    {
      key: 'totalPrice',
      title: I18n.t('總價錢'),
      sorter:true
    },
    {
      key: 'refundAmount',
      title: I18n.t('退款金額'),
      sorter:true
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('createdAt'),
      sorter:true
    },
  ]

  const subColumns = [
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'product',
      title: I18n.t('產品唯一碼'),
      render: (value, record) => {
        return value && value.uniqueCode
      },
    },
    {
      key: 'product',
      title: I18n.t('產品名稱'),
      render: (value, record) => {
        return value && value.name[userData.locale]
      },
    },
    {
      key: 'product',
      title: I18n.t('產品類型'),
      render: (value, record) => {
        if(value.type === 'REDEMPTION'){
          return I18n.t('productTypeRedemption')
        }else if(value.type === 'SALE'){
          return I18n.t('productTypeSale')
        }
      },
    },
    {
      key: 'unitPrice',
      title: I18n.t('單價'),
    },
    {
      key: 'amount',
      title: I18n.t('數量'),
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('createdAt'),
    },
  ]

  const searchColumns = [
    {
      template: 'select',
      key: 'shopId',
      title: I18n.t('所有店鋪'),
      options: shopOptions
    },
    {
      template: 'select',
      key: 'operatorUserId',
      title: I18n.t('所有操作員'),
      options: userOptions
    },
    {
      template: 'select',
      key: 'payment',
      title: I18n.t('所有支付'),
      options: [
        {
          label: I18n.t('八達通'),
          value: 'OCTOPUS'
        },
        {
          label: I18n.t('現金'),
          value: 'CASH'
        },
        {
          label: I18n.t('PAYME'),
          value: 'PAYME'
        },
        {
          label: I18n.t('無'),
          value: ''
        },
      ]
    },
    {
      template: 'rangePicker',
      key: 'rangePickers',
      title: I18n.t('選擇時間段'),
      ranges: ToolTable.ranges
    },
  ]

  const searchQueryParams = values => {
    const result = ToolTable.formatRangePicker(values.rangePickers)
    return {
      search: values.search ? `%${values.search}%` : undefined,
      shopId:values.shopId,
      operatorUserId:values.operatorUserId,
      payment:values.payment,
      startTime:result.startTime,
      endTime:result.endTime,
    }
  }

  const loadDataSource = async (body) => {
    const shopSaleRecordRes = await ToolRequest.request('GET', '/sale-record', {
      includeSaleRecordProducts:true,
      ...body,
    })
    setTableParams(body)
    console.dir(shopSaleRecordRes)
    return shopSaleRecordRes
  }

  return loaded ? (
    <PageHeader
      title={I18n.t('店鋪銷售記錄列表')}
      extra={[
        (
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              const params = queryString.stringify(tableParams)
              console.dir(params)
              let hiddenElement = document.createElement('a')
              hiddenElement.href = `${metadata.cloudHost}backend/sale-record/export/xlsx?${params}`
              hiddenElement.target = '_blank'
              hiddenElement.click()
            }}
            icon={<DownloadOutlined />}
          >
            {I18n.t('導出Excel')}
          </Button>
        ),
      ]}
    >
      <SuperTable
        showSearchInput
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
        searchColumns={searchColumns}
        expandable={{
          expandedRowRender: (record) => (
            <SuperTable
              columns={subColumns}
              loadDataSource={async ()=>{return {data:record.saleRecordProducts,count:record.saleRecordProducts.length}}}
            />
          ),
          rowExpandable: record => true,
        }}
      />
      {shopSaleRecordEditModal && <ShopSaleRecordEditModal
        key={shopSaleRecordEditModal.key}
        visible={shopSaleRecordEditModal.visible}
        shopSaleRecordId={shopSaleRecordEditModal.shopSaleRecordId}
        onOk={() => {
          setShopSaleRecordEditModal({
            ...shopSaleRecordEditModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setShopSaleRecordEditModal({
            ...shopSaleRecordEditModal,
            visible: false,
          })
        }}
      />}
    </PageHeader>
  ) : <SpinFull/>
};

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { Layout } from 'antd';
import { HomeOutlined, ClusterOutlined, GiftOutlined, NotificationOutlined,FileOutlined,DollarCircleOutlined,UnorderedListOutlined,
  UserOutlined, ShopOutlined, MoneyCollectOutlined, SettingOutlined, GlobalOutlined } from '@ant-design/icons';

import * as ToolDevice from '../../tools/ToolDevice';

import AppMenu from './AppMenu';
import AppHeader from './AppHeader';
import AppContent from './AppContent';

import '../../less/App.less';

export default function AppScreen(props) {
  const [menuCollapsed, setMenuCollapsed] = useState(ToolDevice.isMobile());

  const menu = [
    {
      key: '/',
      title: I18n.t('主頁'),
      match: '^\/$',
    },
    {
      key: '/product',
      title: I18n.t('產品'),
      sub: [
        {
          key: '/product',
          title: I18n.t('產品'),
          match: '^\/product\$',
        },
        {
          key: '/product-type',
          title: I18n.t('產品類型'),
          match: '^\/product-type\$',
        },
        {
          key: '/product-brand',
          title: I18n.t('產品品牌'),
          match: '^\/product-brand\$',
        },
      ],
    },
    {
      key: '/shop',
      title: I18n.t('店鋪'),
      match: '^\/shop\$',
      sub: [
        {
          key: '/shop',
          title: I18n.t('店鋪'),
          match: '^\/shop\$',
        },
        {
          key: '/shop-inventory',
          title: I18n.t('店鋪庫存'),
          match: '^\/shop-inventory\$',
        },
        {
          key: '/shop-replenish-record',
          title: I18n.t('店鋪庫存變動記錄'),
          match: '^\/shop-replenish-record\$',
        },
        {
          key: '/shop-sale-record',
          title: I18n.t('店鋪銷售記錄'),
          match: '^\/shop-sale-record\$',
        },
        {
          key: '/shop-redemption-record',
          title: I18n.t('店鋪取貨記錄'),
          match: '^\/shop-redemption-record\$',
        },
      ],
    },
    {
      key: '/user',
      title: I18n.t('用戶'),
      match: '^\/user',
      sub:[
        {
          key: '/user',
          title: I18n.t('用戶'),
          match: '^\/user\$',
        },
        {
          key: '/user-coin-date',
          title: I18n.t('用戶積分'),
          match: '^\/user-coin-date\$',
        },
      ]
    },
    {
      key: '/region',
      title: I18n.t('地區'),
      match: '^\/region',
    },
  ];

  return (
    <Layout id="mainLayout">
      <AppMenu
        collapsed={menuCollapsed}
        setCollapsed={setMenuCollapsed}
        menu={menu}
      />
      <Layout>
        <AppHeader
          collapsed={menuCollapsed}
          setCollapsed={setMenuCollapsed}
        />
        <AppContent />
      </Layout>
    </Layout>
  );
}

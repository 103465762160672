import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import moment from 'moment'
import queryString from 'querystringify'

import { Dropdown, Menu, Button, Modal, message, PageHeader } from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  EllipsisOutlined, DownloadOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'
import * as ToolTable from '../../tools/ToolTable'

import ShopReplenishRecordEditModal from './ShopReplenishRecordEditModal'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'

export default function ShopReplenishRecordViewScreen (props) {
  const userData = useSelector(state => state.user.value)
  const metadata = useSelector(state => state.metadata.value);
  const [loaded, setLoaded] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random().toString())
  const [shopReplenishRecordEditModal, setShopReplenishRecordEditModal] = useState(null)
  const [shops, setShops] = useState([])
  const [users, setUsers] = useState([])
  const [shopOptions, setShopOptions] = useState([])
  const [userOptions, setUserOptions] = useState([])
  const [tableParams, setTableParams] = useState(null)


  useEffect(() => {
    (async () => {
      const shopRes = await ToolRequest.request('GET', '/shop', {limit:5000})
      setShops(shopRes.data)
      const shopOptions = shopRes.data.map(item => {
        return {label:item.name[userData.locale],value:item.id}
      })
      setShopOptions(shopOptions)
      const userRes = await ToolRequest.request('GET', '/user', {limit:5000,type:'ADMIN'})
      setUsers(userRes.data)
      const userOptions = userRes.data.map(item => {
        return {label:item.fullName,value:item.id}
      })
      setUserOptions(userOptions)
      setLoaded(true)
    })()
  }, [])

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = true
        const canRemove = true
        return canEdit || canRemove ? (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    setShopReplenishRecordEditModal({
                      visible: true,
                      shopReplenishRecordId: record.id,
                      key: Math.random().toString(),
                    })
                    break
                  }
                }
              }
              }>
                {(
                  <Menu.Item key="edit">
                    <EditOutlined/>
                    {I18n.t('編輯店鋪庫存變動記錄')}
                  </Menu.Item>
                )}
              </Menu>
            }>
            <Button
              type="primary"
              icon=<EllipsisOutlined/>
            />
          </Dropdown>
        ) : null
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'shopId',
      title: I18n.t('店號'),
      render: (value, record) => {
        const shop = shops.find(item=>item.id === value)
        return shop && shop.shopNo
      },
    },
    {
      key: 'shopId',
      title: I18n.t('店名'),
      render: (value, record) => {
        const shop = shops.find(item=>item.id === value)
        return shop && shop.name ? shop.name['zh-tw'] : ''
      },
    },
    {
      key: 'operatorUserId',
      title: I18n.t('操作員'),
      render: (value, record) => {
        const user = users.find(item=>item.id === value)
        return user && user.fullName
      },
    },
    {
      key: 'product',
      title: I18n.t('產品唯一碼'),
      render: (value, record) => {
        return value && value.uniqueCode
      },
    },
    {
      key: 'product',
      title: I18n.t('產品名稱'),
      render: (value, record) => {
        return value && value.name[userData.locale]
      },
    },
    {
      key: 'product',
      title: I18n.t('類型'),
      render: (value, record) => {
        if(!value){
          return ''
        }
        if(value.type === 'REDEMPTION'){
          return I18n.t('productTypeRedemption')
        }else if(value.type === 'SALE'){
          return I18n.t('productTypeSale')
        }
      },
    },
    {
      key: 'description',
      title: I18n.t('庫存變動原因'),
      render: (value, record) => {
        switch (value) {
          case 'SALE':{
            return I18n.t('replenishRecordSale')
          }
          case 'REDEMPTION':{
            return I18n.t('replenishRecordRedemption')
          }
          case 'NORMAL':{
            return I18n.t('replenishRecordNormal')
          }
          case 'ADJUSTMENT':{
            return I18n.t('replenishRecordAdjustment')
          }
        }
      },
    },
    {
      key: 'remark',
      title: I18n.t('備註'),
    },
    {
      key: 'amount',
      title: I18n.t('數量'),
      sorter:true
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('createdAt'),
      sorter:true
    },
  ]

  const searchColumns = [
    {
      template: 'select',
      key: 'shopId',
      title: I18n.t('所有店鋪'),
      options: shopOptions
    },
    {
      template: 'select',
      key: 'operatorUserId',
      title: I18n.t('所有操作員'),
      options: userOptions
    },
    {
      template: 'select',
      key: 'description',
      title: I18n.t('所有變動原因'),
      options: [
        {
          label: I18n.t('出入貨'),
          value: 'NORMAL'
        },
        {
          label: I18n.t('兌換'),
          value: 'REDEMPTION'
        },
        {
          label: I18n.t('銷售'),
          value: 'SALE'
        },
        {
          label: I18n.t('盤點'),
          value: 'ADJUSTMENT'
        },
      ]
    },
    {
      template: 'rangePicker',
      key: 'rangePickers',
      title: I18n.t('選擇時間段'),
      ranges: ToolTable.ranges
    },
  ]

  const searchQueryParams = values => {
    const result = ToolTable.formatRangePicker(values.rangePickers)
    return {
      search: values.search ? `%${values.search}%` : undefined,
      shopId:values.shopId,
      operatorUserId:values.operatorUserId,
      description:values.description,
      startTime:result.startTime,
      endTime:result.endTime,
    }
  }

  const loadDataSource = async (body) => {
    console.dir(body)
    const shopReplenishRecordRes = await ToolRequest.request('GET', '/shop-replenish-record', {
      includeProduct:true,
      ...body,
    })
    setTableParams(body)
    console.dir(shopReplenishRecordRes)
    return shopReplenishRecordRes
  }

  return loaded ? (
    <PageHeader
      title={I18n.t('店鋪庫存變動記錄列表')}
      extra={[
        (
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              const params = queryString.stringify(tableParams)
              console.dir(params)
              let hiddenElement = document.createElement('a')
              hiddenElement.href = `${metadata.cloudHost}backend/replenish-record/export/xlsx?${params}`
              hiddenElement.target = '_blank'
              hiddenElement.click()
            }}
            icon={<DownloadOutlined />}
          >
            {I18n.t('導出Excel')}
          </Button>
        ),
      ]}
    >
      <SuperTable
        showSearchInput
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
        searchColumns={searchColumns}
      />
      {shopReplenishRecordEditModal && <ShopReplenishRecordEditModal
        key={shopReplenishRecordEditModal.key}
        visible={shopReplenishRecordEditModal.visible}
        shopReplenishRecordId={shopReplenishRecordEditModal.shopReplenishRecordId}
        onOk={() => {
          setShopReplenishRecordEditModal({
            ...shopReplenishRecordEditModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setShopReplenishRecordEditModal({
            ...shopReplenishRecordEditModal,
            visible: false,
          })
        }}
      />}
    </PageHeader>
  ) : <SpinFull/>
};

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, setLocale } from 'react-redux-i18n'

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Select,
  message,
  Switch,
  Space,
  InputNumber,
  Radio,
} from 'antd'
import { MinusCircleOutlined, EditOutlined, PlusOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'

const formLayout = {
  labelCol: {
    md: {span: 5},
    sm: {span: 24},
  },
  wrapperCol: {
    md: {span: 16},
  },
}

const formTailLayout = {
  wrapperCol: {
    md: {offset: 5, span: 16},
  },
}

const {TextArea} = Input
const {Option} = Select

export default function ProductBrandAddModal (props) {
  const userData = useSelector(state => state.user.value)
  const metadata = useSelector(state => state.metadata.value)
  const {visible, onOk, onCancel} = props
  const user = useSelector(state => state.user.value)
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        
      } catch (e) {
        message.error(e)
      }
      setLoaded(true)
    })()
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    console.dir(values)
    try {
      let name = {}
      for (let locale of metadata.supportedLocales) {
        name[locale] = values[`name[${locale}]`]
      }
      const data = {
        name,
        remark: values.remark
      }
      await ToolRequest.request('POST', `/product-brand`, data)
      message.success(I18n.t('addSuccessMessage'))
      onOk()
      setLoading(false)
    } catch (err) {
      console.dir(err)
      setLoading(false)
      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'))
        }
      }

      message.error(I18n.t('errorMessageUnknownError'))
    }
  }

  return (
    <Modal
      title={I18n.t('添加產品品牌')}
      visible={visible}
      onCancel={onCancel}
      width="50%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            initialValues={
              {

              }
            }
            onFinish={onFinish}
          >
            {metadata.supportedLocales.map(locale => {
              return (
                <Form.Item
                  key={`name[${locale}]`}
                  label={`${I18n.t('名稱')} (${locale})`}
                  name={`name[${locale}]`}
                  rules={[
                    {
                      required: true,
                      message: I18n.t('errorMessageRequiredInputAny', {
                        name: `${I18n.t('名稱')} (${locale})`,
                      }),
                    },
                  ]}
                >
                  <Input
                    placeholder={`${I18n.t('名稱')} (${locale})`}
                  />
                </Form.Item>
              );
            })}
            <Form.Item
              label={I18n.t('備註')}
              name="remark"
            >
            <TextArea
              placeholder={I18n.t('備註')}
            />
            </Form.Item>
            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('提交')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, setLocale } from 'react-redux-i18n'

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Select,
  message,
  Switch,
  Divider,
  InputNumber,
  Cascader,
} from 'antd'
import {
  MinusCircleOutlined,
  EditOutlined,
  PlusOutlined,
  AppstoreImportOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'
import Import from '../../components/Import'
import moment from 'moment'

const formLayout = {
  labelCol: {
    md: {span: 5},
    sm: {span: 24},
  },
  wrapperCol: {
    md: {span: 16},
  },
}

const formTailLayout = {
  wrapperCol: {
    md: {offset: 5, span: 16},
  },
}

const {TextArea} = Input
const {Option} = Select

export default function BatchUpdateModal (props) {
  const metadata = useSelector(state => state.metadata.value)
  const userData = useSelector(state => state.user.value)
  const {visible, onOk, onCancel} = props
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [shopInventories, setShopInventories] = useState([])
  const [shopInventoryOptions, setShopInventoryOptions] = useState([])

  useEffect(() => {
    (async () => {
      try {
        const shopRes = await ToolRequest.request('GET', `/shop`)
        const shopInventoryRes = await ToolRequest.request('GET', `/shop-inventory`,{includeProduct:true,limit:10000})
        console.dir(shopInventoryRes)
        setShopInventories(shopInventoryRes.data)
        let options = []
        for (let shop of shopRes.data){
          const inventories = shopInventoryRes.data.filter(inventory=>inventory.shopId === shop.id)
          const children = inventories.map(inventory=>{
            return {
              value: inventory.id,
              label: inventory.product ? inventory.product.name[userData.locale]:'',
            }
          })
          options.push({
            label:shop.name[userData.locale],
            value:shop.id,
            children
          })
        }
        console.dir(options)
        setShopInventoryOptions(options)
      } catch (e) {
        message.error(e)
      }
      setLoaded(true)
    })()
  }, [])

  const onFinish = async (values) => {
    console.dir(values)
    try {
      let shopInventoryIds = []
      const inventories = values.inventories
      for (let dataArr of inventories){
        if(dataArr.length === 1){
          const shopInventoryArr = shopInventories.filter(item=>item.shopId === dataArr[0])
          for (let shopInventory of shopInventoryArr){
            if(shopInventoryIds.indexOf(shopInventory.id) === -1){
              shopInventoryIds.push(shopInventory.id)
            }
          }
        }else {
          dataArr.forEach((item,index)=>{
            if(index >= 1){
              if(shopInventoryIds.indexOf(item) === -1){
                shopInventoryIds.push(item)
              }
            }
          })
        }
      }
      const data = {
        shopInventoryIds,
        amount: values.amount,
        description: values.description,
      }
      console.dir(shopInventoryIds)
      await ToolRequest.request('POST', `/backend/shop-inventory/batch-update`, data)
      message.success('批量更新庫存成功')
      onOk()
      setLoading(false)
    } catch (err) {
      console.dir(err)
      setLoading(false)
      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'))
        }
      }
      const errorMessage = err.message || message.error(I18n.t('errorMessageUnknownError'))
      message.error(errorMessage)
    }
  }

  return (
    <Modal
      title={I18n.t('批量更新庫存')}
      visible={visible}
      onCancel={onCancel}
      width="50%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            initialValues={{
              beginAt:moment()
            }}
            onFinish={onFinish}
          >
            <Form.Item
              label="選擇庫存產品"
              name="inventories"
              rules={[
                {
                  required: true
                },
              ]}
            >
              <Cascader
                style={{
                  width: '100%',
                }}
                showSearch={true}
                options={shopInventoryOptions}
                multiple
                maxTagCount="responsive"
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('数量 (+ -)')}
              name="amount"
              rules={[
                {
                  required: true
                },
              ]}
            >
              <InputNumber
                placeholder={I18n.t('数量 (+ -)')}
                step={1} style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('操作類別')}
              name="description"
              rules={[
                {
                  required: true
                },
              ]}
            >
              <Select placeholder={I18n.t('操作類別')}>
                <Option value="NORMAL">
                  {I18n.t('出入貨')}
                </Option>
                <Option value="ADJUSTMENT">
                  {I18n.t('盤點')}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('提交')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import * as ToolDevice from '../../tools/ToolDevice';
import * as ToolRequest from '../../tools/ToolRequest';

import { Layout, Dropdown, Menu, Button, message, Space, Badge, Avatar, Tooltip } from 'antd';
import { MenuUnfoldOutlined, NotificationOutlined, MailOutlined, MenuFoldOutlined, DownOutlined, LogoutOutlined, SettingOutlined, EditOutlined, ShopOutlined, LoadingOutlined, MessageFilled, CheckOutlined, CloseOutlined, MessageOutlined } from '@ant-design/icons';
import ChangePasswordModal from './ChangePasswordModal';
import UserSettingModal from './UserSettingModal';
const { Header } = Layout;

export default function AppHeader(props) {
  const userData = useSelector(state => state.user.value);
  const { collapsed, setCollapsed } = props;
  const [changePasswordModal, setChangePasswordModal] = useState(null);
  const [userSettingModal, setUserSettingModal] = useState(null);
  const logout = () => {
    return ToolRequest.request('DELETE', '/auth')
      .then(res => {
        window.location.reload();
      })
      .catch(err => {
        message.error(I18n.t('errorMessageUnknownError'));
      });
  };

  return (
    <Header id="mainLayoutHeader">
        <div className="rightContainer">
          <Space size="middle">
            <Dropdown
              type="default"
              onClick={() => {
              }}
              overlay={
                <Menu onClick={value => {
                  switch (value.key) {
                    case 'user-setting': {
                      setUserSettingModal({
                        visible: true,
                        key: Math.random().toString(),
                      });
                      break;
                    }
                    case 'logout': {
                      logout();
                      break;
                    }
                    case 'change-password': {
                      setChangePasswordModal({
                        visible: true,
                        key: Math.random().toString(),
                      });
                      break;
                    }
                  }
                }
                }>
                  <Menu.Item key="user-setting">
                    <SettingOutlined />
                    {I18n.t('個人設定')}
                  </Menu.Item>
                  <Menu.Item key="change-password">
                    <EditOutlined />
                    {I18n.t('changePassword')}
                  </Menu.Item>
                  <Menu.Item key="logout">
                    <LogoutOutlined />
                    {I18n.t('logout')}
                  </Menu.Item>
                </Menu>
              }>
              <Button>
                {I18n.t('myAccount')} <DownOutlined />
              </Button>
            </Dropdown>
          </Space>
        </div>
      <div style={{float: 'left'}}>
        {collapsed ? (
          <MenuUnfoldOutlined
            className="trigger"
            onClick={() => setCollapsed(false)}
          />
        ) : (
          <MenuFoldOutlined
            className="trigger"
            onClick={() => setCollapsed(true)}
          />
        )}
      </div>
      <div className="user-message">
        <div>
          {I18n.t('appHeaderGreetings')}
        </div>
        <div>
          {I18n.t('appHeaderCurrentUser')}: {userData ? userData.fullName : ''}
        </div>
      </div>
      {changePasswordModal && <ChangePasswordModal
        key={changePasswordModal.key}
        visible={changePasswordModal.visible}
        onOk={() => {
          setChangePasswordModal({
            ...changePasswordModal,
            visible: false,
          });
        }}
        onCancel={() => {
          setChangePasswordModal({
            ...changePasswordModal,
            visible: false,
          });
        }}
      />}
      {userSettingModal && <UserSettingModal
        key={userSettingModal.key}
        visible={userSettingModal.visible}
        onOk={() => {
          setUserSettingModal({
            ...userSettingModal,
            visible: false,
          });
        }}
        onCancel={() => {
          setUserSettingModal({
            ...userSettingModal,
            visible: false,
          });
        }}
      />}
    </Header>
  );
};

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'

import { Dropdown, Menu, Button, Modal, message, PageHeader } from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  EllipsisOutlined,
  DownloadOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'

import ProductEditModal from './ProductEditModal'
import ProductAddModal from './ProductAddModal'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'
import Import from '../../components/Import'

export default function ProductViewScreen (props) {
  const userData = useSelector(state => state.user.value)
  const metadata = useSelector(state => state.metadata.value);
  const [loaded, setLoaded] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random().toString())
  const [productTypes, setProductTypes] = useState([])
  const [productBrands, setProductBrands] = useState([])
  const [productEditModal, setProductEditModal] = useState(null)
  const [productAddModal, setProductAddModal] = useState(null)
  const [fileList, setFileList] = useState([]);
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [productBrandOptions, setProductBrandOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const productTypeRes = await ToolRequest.request('GET', '/product-type',{limit:metadata.maxLimit})
      setProductTypes(productTypeRes.data)
      const productTypeOptions = productTypeRes.data.map(item => {
        return {label:item.name[userData.locale],value:item.id}
      })
      setProductTypeOptions(productTypeOptions)
      const productBrandRes = await ToolRequest.request('GET', '/product-brand',{limit:metadata.maxLimit})
      setProductBrands(productBrandRes.data)
      const productBrandOptions = productBrandRes.data.map(item => {
        return {label:item.name[userData.locale],value:item.id}
      })
      setProductBrandOptions(productBrandOptions)
      setLoaded(true)
    })()
  }, [tableKey])

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = true
        const canRemove = true
        return canEdit || canRemove ? (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    setProductEditModal({
                      visible: true,
                      productId: record.id,
                      key: Math.random().toString(),
                    })
                    break
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: I18n.t('removeConfirmMessage'),
                      okType: 'danger',
                      onOk: () => {
                        (async () => {
                          try {
                            await ToolRequest.request('DELETE',
                              `/product/${record.id}`)
                            message.success(I18n.t('removeSuccessMessage'))
                            setTableKey(Math.random().toString())
                          } catch (err) {
                            message.error(I18n.t('removeErrorMessage'))
                          }
                        })()
                      },
                    })
                    break
                  }
                }
              }
              }>
                {(
                  <Menu.Item key="edit">
                    <EditOutlined/>
                    {I18n.t('編輯產品')}
                  </Menu.Item>
                )}
                {(
                  <Menu.Item key="remove">
                    <CloseOutlined/>
                    {I18n.t('刪除產品')}
                  </Menu.Item>
                )}
              </Menu>
            }>
            <Button
              type="primary"
              icon=<EllipsisOutlined/>
            />
          </Dropdown>
        ) : null
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'type',
      title: I18n.t('類型'),
      sorter:true,
      render: (value, record) => {
        if(value){
          if(value === 'REDEMPTION'){
            return I18n.t('productTypeRedemption')
          }else if(value === 'SALE'){
            return I18n.t('productTypeSale')
          }
        }
      },
    },
    {
      key: 'uniqueCode',
      title: I18n.t('唯一碼'),
      sorter:true
    },
    {
      key: 'name',
      title: I18n.t('名稱'),
      render: (value, record) => {
        return value && value[userData.locale]
      },
    },
    {
      key: 'productTypeId',
      title: I18n.t('產品類型'),
      render: (value, record) => {
        const productType = productTypes.find(item=>item.id === value)
        return productType && productType.name[userData.locale]
      },
    },
    {
      key: 'productBrandId',
      title: I18n.t('產品品牌'),
      render: (value, record) => {
        const productBrand = productBrands.find(item=>item.id === value)
        return productBrand && productBrand.name[userData.locale]
      },
    },
    {
      template: 'isEnabled',
      key: 'forSale',
      title: I18n.t('啟用'),
      sorter:true,
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('createdAt'),
      sorter:true
    },
  ]

  const searchColumns = [
    {
      template: 'select',
      key: 'type',
      title: I18n.t('所有类型'),
      options: [
        {
          label: I18n.t('productTypeRedemption'),
          value: 'REDEMPTION'
        },
        {
          label: I18n.t('productTypeSale'),
          value: 'SALE'
        },
      ],
    },
    {
      template: 'select',
      key: 'productTypeId',
      title: I18n.t('產品類型'),
      options: productTypeOptions
    },
    {
      template: 'select',
      key: 'productBrandId',
      title: I18n.t('產品品牌'),
      options: productBrandOptions
    },
    {
      template: 'select',
      key: 'forSale',
      title: I18n.t('在售狀態'),
      defaultValue:true,
      options: [
        {
          label:'在售',
          value:true,
        },
        {
          label:'停售',
          value:false,
        },
      ]
    },
  ]

  const searchQueryParams = values => {
    return {
      search: values.search ? `%${values.search}%` : undefined,
      type: values.type,
      productTypeId: values.productTypeId,
      productBrandId: values.productBrandId,
      forSale: values.forSale,
    }
  }

  const loadDataSource = async (body) => {
    const productRes = await ToolRequest.request('GET', '/product', {
      ...body,
    })
    return productRes
  }

  return loaded ? (
    <PageHeader
      title={I18n.t('產品列表')}
      extra={[
        // (
        //   <Import
        //     accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        //     action={`${metadata.cloudHost}import/product`}
        //     listType="text"
        //     maxCount={1}
        //     fileList={fileList}
        //     showUploadList={false}
        //     setFileList={setFileList}
        //     buttonText="批量導入產品 (*.xlsx;*.xls)"
        //     onOk={() => {
        //       setTableKey(Math.random().toString())
        //     }}
        //   />
        // ),
        // (<Button
        //   type="primary"
        //   shape="round"
        //   href={`${metadata.publicHost}templates/product-template.xlsx`}
        //   icon={<DownloadOutlined />}
        // >
        //   產品模板
        // </Button>),
        (
          <Button
            key={Math.random().toString()}
            type="primary"
            icon={<PlusOutlined/>}
            onClick={() => setProductAddModal({
              visible: true,
              key: Math.random().toString(),
            })}
          >
            {I18n.t('add')}
          </Button>
        ),
      ]}
    >
      <SuperTable
        showSearchInput
        searchQueryParams={searchQueryParams}
        searchColumns={searchColumns}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
      />
      {productEditModal && <ProductEditModal
        key={productEditModal.key}
        visible={productEditModal.visible}
        productId={productEditModal.productId}
        onOk={() => {
          setProductEditModal({
            ...productEditModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setProductEditModal({
            ...productEditModal,
            visible: false,
          })
        }}
      />}
      {productAddModal && <ProductAddModal
        key={productAddModal.key}
        visible={productAddModal.visible}
        onOk={() => {
          setProductAddModal({
            ...productAddModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setProductAddModal({
            ...productAddModal,
            visible: false,
          })
        }}
      />}
    </PageHeader>
  ) : <SpinFull/>
};

import React, { useState } from 'react'
import { Route, Switch, Redirect} from 'react-router-dom'

import { Layout } from 'antd'

import HomeScreen from '../Home/HomeScreen';
import RegionViewScreen from '../Region/RegionViewScreen'
import UserViewScreen from '../User/UserViewScreen'
import ProductViewScreen from '../Product/ProductViewScreen'
import ProductTypeViewScreen from '../ProductType/ProductTypeViewScreen'
import ProductBrandViewScreen from '../ProductBrand/ProductBrandViewScreen'
import ShopViewScreen from '../Shop/ShopViewScreen'
import ShopInventoryViewScreen from '../ShopInventory/ShopInventoryViewScreen'
import ShopReplenishRecordViewScreen from '../ShopReplenishRecord/ShopReplenishRecordViewScreen'
import ShopSaleRecordViewScreen from '../ShopSaleRecord/ShopSaleRecordViewScreen'
import ShopRedemptionRecordViewScreen from '../ShopRedemptionRecord/ShopRedemptionRecordViewScreen'
import UserCoinDateViewScreen from '../UserCoinDate/UserCoinDateViewScreen'

const {Content} = Layout

export default function AppContent (props) {
  return (
    <Content id="mainLayoutContent">
      <div>
        <Switch>
          <Route path="/product" component={ProductViewScreen}/>
          <Route path="/product-type" component={ProductTypeViewScreen}/>
          <Route path="/product-brand" component={ProductBrandViewScreen}/>
          <Route path="/shop" component={ShopViewScreen}/>
          <Route path="/shop-inventory" component={ShopInventoryViewScreen}/>
          <Route path="/shop-replenish-record" component={ShopReplenishRecordViewScreen}/>
          <Route path="/shop-sale-record" component={ShopSaleRecordViewScreen}/>
          <Route path="/shop-redemption-record" component={ShopRedemptionRecordViewScreen}/>
          <Route path="/user-coin-date" component={UserCoinDateViewScreen}/>
          <Route path="/region" component={RegionViewScreen}/>
          <Route path="/user" component={UserViewScreen}/>
          <Route component={HomeScreen} />
        </Switch>
      </div>
    </Content>
  )
};

import React, { useState, useEffect }  from 'react';
import { I18n } from 'react-redux-i18n';

import { Card, Row, Col, Collapse, Descriptions, List, Avatar, Statistic, Tooltip, Typography, Space, Button, Badge } from 'antd';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';

import '../../less/HomeScreen.less';

const { Title, Text, Paragraph } = Typography;
const { Panel } = Collapse;

export default function HomeScreen(props) {
  const [loaded, setLoaded] = useState(false);
  const [redemptionRecordCount, setRedemptionRecordCount] = useState(0);
  const [shopCount, setShopCount] = useState(0);
  const [saleTotalPrice, setSaleTotalPrice] = useState(0);
  const [totalCoin, setTotalCoin] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [memberCount, setMemberCount] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const shopRes = await ToolRequest.request('GET', `/shop`,{limit:5000})
        setShopCount(shopRes.count)
        const redemptionRecordRes = await ToolRequest.request('GET', `/backend/redemption-record/count`)
        setRedemptionRecordCount(redemptionRecordRes.count)
        const totalCoinRes = await ToolRequest.request('GET', `/backend/redemption-record/total-coin`)
        setTotalCoin(totalCoinRes.totalCoin)
        const saleRecordRes = await ToolRequest.request('GET', `/backend/sale-record/total-price`)
        setSaleTotalPrice(saleRecordRes.totalPrice)
        const totalAmountRes = await ToolRequest.request('GET', `/backend/redemption-record-product/total-amount`)
        setTotalAmount(totalAmountRes.totalAmount)
        const memberRes = await ToolRequest.request('GET', `/backend/user/count`,{type:'MEMBER'})
        setMemberCount(memberRes.count)
      } catch (err) {

      }
      setLoaded(true);
    })();
  }, []);

  return loaded ? (
    <>
      <Title level={5} style={{marginTop: 16}}>{I18n.t('店鋪')}</Title>
      <Card>
        <Row gutter={16}>
          <Col span={6}>
            <Statistic
              title={I18n.t('店鋪數量')}
              value={shopCount}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title={I18n.t('總取貨次數')}
              value={redemptionRecordCount}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title={I18n.t('總取貨數量')}
              value={totalAmount}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title={I18n.t('總使用積分')}
              value={totalCoin}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title={I18n.t('總銷售額')}
              value={saleTotalPrice}
              precision={2}
              prefix="$"
            />
          </Col>
        </Row>
      </Card>
      <Title level={5} style={{marginTop: 16}}>{I18n.t('會員')}</Title>
      <Card>
        <Row gutter={16}>
          <Col span={6}>
            <Statistic
              title={I18n.t('會員数量')}
              value={memberCount}
            />
          </Col>
        </Row>
      </Card>
    </>
  ) : <SpinFull />;
};

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import queryString from 'querystringify'
import { I18n } from 'react-redux-i18n'

import {
  Dropdown,
  Menu,
  Button,
  Select,
  message,
  PageHeader,
} from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  EllipsisOutlined, DownloadOutlined,
} from '@ant-design/icons'


import * as ToolRequest from '../../tools/ToolRequest'

import ShopInventoryEditModal from './ShopInventoryEditModal'
import BatchUpdateModal from './BatchUpdateModal'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'

const {Option} = Select


export default function ShopInventoryViewScreen (props) {
  const userData = useSelector(state => state.user.value)
  const metadata = useSelector(state => state.metadata.value);
  const [loaded, setLoaded] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random().toString())
  const [shopInventoryEditModal, setShopInventoryEditModal] = useState(null)
  const [shops, setShops] = useState([])
  const [batchUpdateModal, setBatchUpdateModal] = useState(null)
  const [shopOptions, setShopOptions] = useState([])
  const [tableParams, setTableParams] = useState(null)

  useEffect(() => {
    (async () => {
      const shopRes = await ToolRequest.request('GET', '/shop', {limit:1000})
      setShops(shopRes.data)
      const shopOptions = shopRes.data.map(item => {
        return {label:item.name[userData.locale],value:item.id}
      })
      setShopOptions(shopOptions)
      setLoaded(true)
    })()
  }, [])

  const columns = [
    {
      template: 'action',
      key: 'action',
      fixed: 'left',
      width: 80,
      render: (value, record) => {
        const canEdit = true
        const canRemove = true
        return canEdit || canRemove ? (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    setShopInventoryEditModal({
                      visible: true,
                      shopInventoryId: record.id,
                      key: Math.random().toString(),
                    })
                    break
                  }
                }
              }
              }>
                {(
                  <Menu.Item key="edit">
                    <EditOutlined/>
                    {I18n.t('更新庫存')}
                  </Menu.Item>
                )}
              </Menu>
            }>
            <Button
              type="primary"
              icon=<EllipsisOutlined/>
            />
          </Dropdown>
        ) : null
      },
    },
    {
      template: 'id',
      key: 'id',
      title: 'ID',
    },
    {
      key: 'shopId',
      title: I18n.t('店號'),
      render: (value, record) => {
        const shop = shops.find(item=>item.id === value)
        return shop && shop.shopNo
      },
    },
    {
      key: 'shopId',
      title: I18n.t('店名'),
      render: (value, record) => {
        const shop = shops.find(item=>item.id === value)
        return shop && shop.name ? shop.name['zh-tw'] : ''
      },
    },
    {
      key: 'uniqueCode',
      sorter:true,
      title: I18n.t('產品唯一碼'),
      render: (value, record) => {
        return record.product && record.product.uniqueCode
      },
    },
    {
      key: 'product',
      title: I18n.t('產品名稱'),
      render: (value, record) => {
        return value && value.name[userData.locale]
      },
    },
    {
      key: 'product',
      title: I18n.t('類型'),
      render: (value, record) => {
        if(value){
          if(value.type === 'REDEMPTION'){
            return I18n.t('productTypeRedemption')
          }else if(value.type === 'SALE'){
            return I18n.t('productTypeSale')
          }
        }else {
          console.dir(record)
        }
      },
    },
    {
      key: 'amount',
      title: I18n.t('數量'),
      sorter:true
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('createdAt'),
      sorter:true
    },
  ]

  const searchColumns = [
    {
      template: 'select',
      key: 'shopId',
      title: I18n.t('所有店鋪'),
      options: shopOptions
    },
    {
      template: 'select',
      key: 'type',
      title: I18n.t('類型'),
      options: [
        {
          label: I18n.t('productTypeRedemption'),
          value: 'REDEMPTION'
        },
        {
          label: I18n.t('productTypeSale'),
          value: 'SALE'
        },
      ],
    },
  ]

  const searchQueryParams = values => {
    return {
      search: values.search ? `%${values.search}%` : undefined,
      shopId:values.shopId,
      type: values.type,
    }
  }

  const loadDataSource = async (body) => {
    const shopInventoryRes = await ToolRequest.request('GET', '/shop-inventory', {
      includeProduct:true,
      ...body,
    })
    setTableParams(body)
    console.dir(shopInventoryRes)
    return shopInventoryRes
  }

  return loaded ? (
    <PageHeader
      title={I18n.t('店鋪庫存列表')}
      extra={[
        (
          <Button
            type="primary"
            shape="round"
            onClick={()=>{
              setBatchUpdateModal({
                visible: true,
                key: Math.random().toString(),
              })
            }}
            icon={<PlusOutlined/>}
          >
            批量更新庫存
          </Button>
        ),
        (
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              const params = queryString.stringify(tableParams)
              console.dir(params)
              let hiddenElement = document.createElement('a')
              hiddenElement.href = `${metadata.cloudHost}backend/shop-inventory/export/xlsx?${params}`
              hiddenElement.target = '_blank'
              hiddenElement.click()
            }}
            icon={<DownloadOutlined />}
          >
            {I18n.t('導出Excel')}
          </Button>
        ),
      ]}
    >
      <SuperTable
        showSearchInput
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
        searchColumns={searchColumns}
      />
      {shopInventoryEditModal && <ShopInventoryEditModal
        key={shopInventoryEditModal.key}
        visible={shopInventoryEditModal.visible}
        shopInventoryId={shopInventoryEditModal.shopInventoryId}
        onOk={() => {
          setShopInventoryEditModal({
            ...shopInventoryEditModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setShopInventoryEditModal({
            ...shopInventoryEditModal,
            visible: false,
          })
        }}
      />}
      {batchUpdateModal && <BatchUpdateModal
        key={batchUpdateModal.key}
        visible={batchUpdateModal.visible}
        onOk={() => {
          setBatchUpdateModal({
            ...batchUpdateModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setBatchUpdateModal({
            ...batchUpdateModal,
            visible: false,
          })
        }}
      />}
    </PageHeader>
  ) : <SpinFull/>
};

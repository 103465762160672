import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, setLocale } from 'react-redux-i18n'

import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Select,
  message,
  Switch,
  Space,
  InputNumber,
  Radio,
} from 'antd'
import { MinusCircleOutlined, EditOutlined, PlusOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'

const formLayout = {
  labelCol: {
    md: {span: 5},
    sm: {span: 24},
  },
  wrapperCol: {
    md: {span: 16},
  },
}

const formTailLayout = {
  wrapperCol: {
    md: {offset: 5, span: 16},
  },
}

const {TextArea} = Input
const {Option} = Select

export default function ProductAddModal (props) {
  const userData = useSelector(state => state.user.value)
  const metadata = useSelector(state => state.metadata.value)
  const {visible, onOk, onCancel} = props
  const user = useSelector(state => state.user.value)
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [productTypeOptions, setProductTypeOptions] = useState([])
  const [productBrandOptions, setProductBrandOptions] = useState([])

  useEffect(() => {
    (async () => {
      try {
        const productTypeRes = await ToolRequest.request('GET', '/product-type')
        const productTypeOptions = productTypeRes.data.map(item => {
          return <Option key={item.id}>{item.name[userData.locale]}</Option>
        })
        setProductTypeOptions(productTypeOptions)
        const productBrandRes = await ToolRequest.request('GET', '/product-brand')
        const productBrandOptions = productBrandRes.data.map(item => {
          return <Option key={item.id}>{item.name[userData.locale]}</Option>
        })
        setProductBrandOptions(productBrandOptions)
      } catch (e) {
        message.error(e)
      }
      setLoaded(true)
    })()
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    console.dir(values)
    try {
      let name = {}
      for (let locale of metadata.supportedLocales) {
        name[locale] = values[`name[${locale}]`]
      }
      let description = {}
      for (let locale of metadata.supportedLocales) {
        description[locale] = values[`description[${locale}]`]
      }
      const data = {
        name,
        description,
        productTypeId: values.productTypeId,
        productBrandId: values.productBrandId,
        unitPrice: values.unitPrice,
        unitCoin: values.unitCoin,
        forSale: values.forSale,
        remark: values.remark,
        type: values.type,
        uniqueCode: values.uniqueCode,
      }
      await ToolRequest.request('POST', `/product`, data)
      message.success(I18n.t('addSuccessMessage'))
      onOk()
      setLoading(false)
    } catch (err) {
      console.dir(err)
      setLoading(false)
      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'))
        }
      }

      message.error(I18n.t('errorMessageUnknownError'))
    }
  }

  return (
    <Modal
      title={I18n.t('添加產品')}
      visible={visible}
      onCancel={onCancel}
      width="50%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            initialValues={
              {
                type:'REDEMPTION'
              }
            }
            onFinish={onFinish}
          >
            <Form.Item
              label="類型"
              name="type"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: `${I18n.t('類型')}`,
                  }),
                },
              ]}
            >
              <Radio.Group>
                <Radio value="REDEMPTION">{I18n.t('productTypeRedemption')}</Radio>
                <Radio value="SALE">{I18n.t('productTypeSale')}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
            >
              {({ getFieldValue }) =>
                getFieldValue('type') === 'SALE' ? (
                  <Form.Item
                    label={I18n.t('單個賣價')}
                    name="unitPrice"
                  >
                    <InputNumber
                      placeholder={I18n.t('單個賣價')}
                      min={0}
                      step={0.01} style={{ width: 200 }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label={I18n.t('單個積分')}
                    name="unitCoin"
                  >
                    <InputNumber
                      placeholder={I18n.t('單個積分')}
                      min={0}
                      step={1} style={{ width: 200 }}
                    />
                  </Form.Item>
                )
              }
            </Form.Item>
            <Form.Item
              label={I18n.t('唯一碼')}
              name="uniqueCode"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: `${I18n.t('唯一碼')}`,
                  }),
                },
              ]}
            >
              <Input
                placeholder={I18n.t('唯一碼')}
              />
            </Form.Item>
            <Form.Item
              label="在售"
              name="forSale"
              valuePropName="checked"
            >
              <Switch defaultChecked={true}/>
            </Form.Item>
            {metadata.supportedLocales.map(locale => {
              return (
                <Form.Item
                  key={`name[${locale}]`}
                  label={`${I18n.t('名稱')} (${locale})`}
                  name={`name[${locale}]`}
                  rules={[
                    {
                      required: true,
                      message: I18n.t('errorMessageRequiredInputAny', {
                        name: `${I18n.t('名稱')} (${locale})`,
                      }),
                    },
                  ]}
                >
                  <Input
                    placeholder={`${I18n.t('名稱')} (${locale})`}
                  />
                </Form.Item>
              );
            })}
            {metadata.supportedLocales.map(locale => {
              return (
                <Form.Item
                  key={`description[${locale}]`}
                  label={`${I18n.t('描述')} (${locale})`}
                  name={`description[${locale}]`}
                >
                  <Input
                    placeholder={`${I18n.t('描述')} (${locale})`}
                  />
                </Form.Item>
              );
            })}
            <Form.Item
              label={I18n.t('產品類型')}
              name="productTypeId"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: `${I18n.t('產品類型')}`,
                  }),
                },
              ]}
            >
              <Select
                showSearch
                placeholder={I18n.t('產品類型')}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {productTypeOptions}
              </Select>
            </Form.Item>
            <Form.Item
              label={I18n.t('產品品牌')}
              name="productBrandId"
              rules={[
                {
                  required: true,
                  message: I18n.t('errorMessageRequiredInputAny', {
                    name: `${I18n.t('產品品牌')}`,
                  }),
                },
              ]}
            >
              <Select
                showSearch
                placeholder={I18n.t('產品品牌')}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {productBrandOptions}
              </Select>
            </Form.Item>
            <Form.Item
              label={I18n.t('備註')}
              name="remark"
            >
            <TextArea
              placeholder={I18n.t('備註')}
            />
            </Form.Item>
            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('提交')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};
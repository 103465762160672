import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'

import { Dropdown, Menu, Button, Modal, message, PageHeader } from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  EllipsisOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'

import ShopEditModal from './ShopEditModal'
import ShopAddModal from './ShopAddModal'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'

export default function ShopViewScreen (props) {
  const userData = useSelector(state => state.user.value)
  const [loaded, setLoaded] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random().toString())
  const [shopEditModal, setShopEditModal] = useState(null)
  const [shopAddModal, setShopAddModal] = useState(null)
  const [regions, setRegions] = useState([])


  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = true
        const canRemove = true
        return canEdit || canRemove ? (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    setShopEditModal({
                      visible: true,
                      shopId: record.id,
                      key: Math.random().toString(),
                    })
                    break
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: I18n.t('removeConfirmMessage'),
                      okType: 'danger',
                      onOk: () => {
                        (async () => {
                          try {
                            await ToolRequest.request('DELETE',
                              `/shop/${record.id}`)
                            message.success(I18n.t('removeSuccessMessage'))
                            setTableKey(Math.random().toString())
                          } catch (err) {
                            message.error(I18n.t('removeErrorMessage'))
                          }
                        })()
                      },
                    })
                    break
                  }
                }
              }
              }>
                {(
                  <Menu.Item key="edit">
                    <EditOutlined/>
                    {I18n.t('編輯店鋪')}
                  </Menu.Item>
                )}
                {(
                  <Menu.Item key="remove">
                    <CloseOutlined/>
                    {I18n.t('刪除店鋪')}
                  </Menu.Item>
                )}
              </Menu>
            }>
            <Button
              type="primary"
              icon=<EllipsisOutlined/>
            />
          </Dropdown>
        ) : null
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'shopNo',
      title: I18n.t('店號'),
      sorter:true
    },
    {
      key: 'name',
      title: I18n.t('名稱'),
      render: (value, record) => {
        return value && value[userData.locale]
      },
    },
    {
      key: 'regionId',
      title: I18n.t('地區'),
      render: (value, record) => {
        const region = regions.find(item=>item.id === value)
        return region && region.name[userData.locale]
      },
    },
    {
      key: 'address',
      title: I18n.t('地址')
    },
    {
      key: 'phone',
      title: I18n.t('電話')
    },
    {
      key: 'openHour',
      title: I18n.t('營業時間')
    },
    {
      template: 'isEnabled',
      key: 'isVisible',
      title: I18n.t('啟用'),
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('createdAt'),
      sorter:true
    },
  ]

  const searchQueryParams = values => {
    return {
      searchText: values.search ? `%${values.search}%` : undefined,
    }
  }

  useEffect(() => {
    (async () => {
      const regionRes = await ToolRequest.request('GET', '/region')
      setRegions(regionRes.data)
      setLoaded(true)
    })()
  }, [])

  const loadDataSource = async (body) => {
    const shopRes = await ToolRequest.request('GET', '/shop', {
      ...body,
    })
    return shopRes
  }

  return loaded ? (
    <PageHeader
      title={I18n.t('店鋪列表')}
      extra={[
        (
          <Button
            key={Math.random().toString()}
            type="primary"
            icon={<PlusOutlined/>}
            onClick={() => setShopAddModal({
              visible: true,
              key: Math.random().toString(),
            })}
          >
            {I18n.t('add')}
          </Button>
        ),
      ]}
    >
      <SuperTable
        showSearchInput
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
      />
      {shopEditModal && <ShopEditModal
        key={shopEditModal.key}
        visible={shopEditModal.visible}
        shopId={shopEditModal.shopId}
        onOk={() => {
          setShopEditModal({
            ...shopEditModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setShopEditModal({
            ...shopEditModal,
            visible: false,
          })
        }}
      />}
      {shopAddModal && <ShopAddModal
        key={shopAddModal.key}
        visible={shopAddModal.visible}
        onOk={() => {
          setShopAddModal({
            ...shopAddModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setShopAddModal({
            ...shopAddModal,
            visible: false,
          })
        }}
      />}
    </PageHeader>
  ) : <SpinFull/>
};

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, setLocale } from 'react-redux-i18n'
import moment from 'moment'

import { Modal, Form, Input, Button, Spin, Select, message, DatePicker,Radio,InputNumber } from 'antd'
import { MinusCircleOutlined, AddOutlined, PlusOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest'
import SpinFull from '../../components/SpinFull'

const formLayout = {
  labelCol: {
    md: {span: 5},
    sm: {span: 24},
  },
  wrapperCol: {
    md: {span: 16},
  },
}

const formTailLayout = {
  wrapperCol: {
    md: {offset: 5, span: 16},
  },
}

const {TextArea} = Input
const {Option} = Select

export default function UserCoinDateAddModal (props) {
  const userData = useSelector(state => state.user.value)
  const metadata = useSelector(state => state.metadata.value)
  const {userCoinDateId,visible, onOk, onCancel} = props
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [familyOptions, setFamilyOptions] = useState([])

  useEffect(() => {
    (async () => {
      try {
        const userRes = await ToolRequest.request('GET', `/user`,{type:'MEMBER',sort:'main_family_number',order:'asc'})
        const familyOptions = userRes.data.map(item => {
          return <Option key={item.id}>{item.mainFamilyNumber}</Option>
        })
        setFamilyOptions(familyOptions)
      } catch (e) {
        message.error(e)
      }
      setLoaded(true)
    })()
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    console.dir(values)
    try {
      const data = {
        expiredAt: moment(values.expiredAt).utcOffset(metadata.utcOffset).format('YYYY-MM-DD'),
        type: values.type,
        userId: values.userId,
        amount: values.amount,
      }
      await ToolRequest.request('POST', `/user-coin-date`, data)
      message.success(I18n.t('addSuccessMessage'))
      onOk()
      setLoading(false)
    } catch (err) {
      console.dir(err)
      setLoading(false)
      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error(I18n.t('errorMessageUnknownError'))
        }
      }

      message.error(I18n.t('errorMessageUnknownError'))
    }
  }

  return (
    <Modal
      title={I18n.t('添加用戶積分')}
      open={visible}
      onCancel={onCancel}
      width="50%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            initialValues={
              {
                type:'EXTRA'
              }
            }
            onFinish={onFinish}
          >
            <Form.Item
              label="類型"
              name="type"
              rules={[
                {
                  required: true
                },
              ]}
            >
              <Radio.Group>
                <Radio value="EXTRA">{I18n.t('額外贈送')}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={I18n.t('到期日')}
              name="expiredAt"
              rules={[
                {
                  required: true
                },
              ]}
            >
              <DatePicker
                placeholder={I18n.t('到期日')}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('數量')}
              name="amount"
              rules={[
                {
                  required: true
                },
              ]}
            >
              <InputNumber
                placeholder={I18n.t('數量')}
                min={1}
                step={1} style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item
              label={I18n.t('家庭')}
              name="userId"
              rules={[
                {
                  required: true
                },
              ]}
            >
              <Select
                showSearch
                placeholder={I18n.t('家庭')}
                optionFilterProp="children"
              >
                {familyOptions}
              </Select>
            </Form.Item>
            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                {I18n.t('提交')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull/>}
    </Modal>
  )
};
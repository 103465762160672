import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'
import moment from 'moment'

import { Dropdown, Menu, Button, Typography, message, PageHeader } from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  EllipsisOutlined,
} from '@ant-design/icons'

import * as ToolRequest from '../../tools/ToolRequest'

import UserCoinDateEditModal from './UserCoinDateEditModal'
import UserCoinDateAddModal from './UserCoinDateAddModal'
import SpinFull from '../../components/SpinFull'
import SuperTable from '../../components/SuperTable'

const {Text} = Typography

export default function UserCoinDateViewScreen (props) {
  const userData = useSelector(state => state.user.value)
  const metadata = useSelector(state => state.metadata.value)
  const [loaded, setLoaded] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random().toString())
  const [userCoinDateEditModal, setUserCoinDateEditModal] = useState(null)
  const [userCoinDateAddModal, setUserCoinDateAddModal] = useState(null)

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        const canEdit = true
        const canRemove = true
        return canEdit || canRemove ? (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    setUserCoinDateEditModal({
                      visible: true,
                      userCoinDateId: record.id,
                      key: Math.random().toString(),
                    })
                    break
                  }
                }
              }
              }>
                {(
                  <Menu.Item key="edit">
                    <EditOutlined/>
                    {I18n.t('編輯用戶積分')}
                  </Menu.Item>
                )}
              </Menu>
            }>
            <Button
              type="primary"
              icon=<EllipsisOutlined/>
            />
          </Dropdown>
        ) : null
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'user',
      title: I18n.t('家庭號'),
      render: (value, record) => {
        return value && value.mainFamilyNumber
      },
    },
    {
      key: 'amount',
      title: I18n.t('剩餘可用積分'),
      sorter:true
    },
    {
      key: 'expiredAt',
      title: I18n.t('到期日'),
      sorter:true,
      render: (value, record) => {
        if (value) {
          const isSameOrAfter = moment(value, 'YYYY-MM-DD').isSameOrAfter(moment().format('YYYY-MM-DD'))
          if (isSameOrAfter) {
            return (<Text type="success">{value}</Text>)
          } else {
            return (<Text type="danger">{value}</Text>)
          }
        }
      },
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('createdAt'),
      sorter:true,
    },
  ]

  const subColumns = [
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'type',
      title: I18n.t('類型'),
      render: (value, record) => {
        switch (value) {
          case 'REGULAR': {
            return I18n.t('userCoinDateRegular')
          }
          case 'EXTRA': {
            return I18n.t('userCoinDateExtra')
          }
          case 'REDEMPTION': {
            return I18n.t('userCoinDateRedemption')
          }
          case 'EXPIRED': {
            return I18n.t('userCoinDateExpired')
          }
        }
      },
    },
    {
      key: 'amount',
      title: I18n.t('數量'),
      render: (value, record) => {
        return value > 0 ? `+${value}` : value
      },
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: I18n.t('createdAt'),
    },
  ]

  const searchColumns = [
    {
      template: 'select',
      key: 'type',
      title: I18n.t('積分類型'),
      defaultValue: 'ONGOING',
      options: [
        {
          label: '有效積分',
          value: 'ONGOING',
        },
        {
          label: '過期積分',
          value: 'ENDED',
        },
      ],
    },
  ]

  const searchQueryParams = values => {
    return {
      search: values.search ? `%${values.search}%` : undefined,
      type: values.type,
    }
  }

  useEffect(() => {
    (async () => {
      setLoaded(true)
    })()
  }, [])

  const loadDataSource = async (body) => {
    const userCoinDateRes = await ToolRequest.request('GET', '/user-coin-date',
      {
        includeUser: true,
        includeUserCoinDateRecords: true,
        sort: 'expired_at',
        order: 'asc',
        ...body,
      })
    console.dir(userCoinDateRes)
    return userCoinDateRes
  }

  return loaded ? (
    <PageHeader
      title={I18n.t('用戶積分列表')}
      extra={[
        (
          <Button
            key={Math.random().toString()}
            type="primary"
            icon={<PlusOutlined/>}
            onClick={() => setUserCoinDateAddModal({
              visible: true,
              key: Math.random().toString(),
            })}
          >
            {I18n.t('add')}
          </Button>
        ),
      ]}
    >
      <SuperTable
        showSearchInput
        searchQueryParams={searchQueryParams}
        searchColumns={searchColumns}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <SuperTable
              columns={subColumns}
              loadDataSource={async () => {
                return {
                  data: record.userCoinDateRecords,
                  count: record.userCoinDateRecords.length,
                }
              }}
            />
          ),
          rowExpandable: record => true,
        }}
      />
      {userCoinDateAddModal && <UserCoinDateAddModal
        key={userCoinDateAddModal.key}
        visible={userCoinDateAddModal.visible}
        onOk={() => {
          setUserCoinDateAddModal({
            ...userCoinDateAddModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setUserCoinDateAddModal({
            ...userCoinDateAddModal,
            visible: false,
          })
        }}
      />}
      {userCoinDateEditModal && <UserCoinDateEditModal
        key={userCoinDateEditModal.key}
        visible={userCoinDateEditModal.visible}
        userCoinDateId={userCoinDateEditModal.userCoinDateId}
        onOk={() => {
          setUserCoinDateEditModal({
            ...userCoinDateEditModal,
            visible: false,
          })
          setTableKey(Math.random().toString())
        }}
        onCancel={() => {
          setUserCoinDateEditModal({
            ...userCoinDateEditModal,
            visible: false,
          })
        }}
      />}
    </PageHeader>
  ) : <SpinFull/>
};
